<template>
  <RFModal :title="docType | replaceUnderscore | capitalize" :modalId="modalId" hide-footer v-if="document && docType">
    <b-row class="document-container">
      <b-col :cols="isPdf(document.front.format) ? '12' : '6'" v-if="document.front">
        <object class="document" v-if="isPdf(document.front.format)" :data="document.front.path | getAsset"/>
        <img class="image" :src="document.front.path | getAsset" v-else/>
      </b-col>
      <b-col :cols="isPdf(document.back.format) ? '12' : '6'" v-if="document.back">
        <object class="document" v-if="isPdf(document.back.format)" :data="document.back.path | getAsset"/>
        <img class="image" :src="document.back.path | getAsset" v-else/>
      </b-col>
      <b-col cols="12" v-if="document.full">
        <object class="document" v-if="isPdf(document.full.format)" :data="document.full.path | getAsset"/>
        <img class="image" :src="document.full.path | getAsset" v-else/>
      </b-col>
    </b-row>
  </RFModal>
</template>

<script>
import RFModal from '@/components/modals/RFModal'
import { mapState } from 'vuex'

export default {
  name: 'AdminDocumentModal',
  components: {
    RFModal,
  },
  props: {
    docType: String,
  },
  data() {
    return {
      modalId: 'admin-document-modal',
    }
  },
  computed: {
    ...mapState({
      document: state => state.adminUserStore.document
    })
  },
  methods: {
    isPdf(format) {
      return format === 'PDF'
    }
  }
}
</script>

<style lang="scss" scoped>
.document-container {
  width: 100%;

  .image {
    width: 100%;
    max-height: 100%;
  }
  .document {
    max-width: 100%;
    width: 100%;
    height: 70vh;
  }
}
</style>
