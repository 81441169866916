<template>
  <div class="edit-user">
    <div class="admin-title">{{$t('Edit User')}}</div>
    <ValidationObserver ref="validator">
      <form class="edit-user-form" @submit.prevent>
        <b-row>
          <b-col>
            <RFValidation name="Firstname" rules="required">
              <RFInput label="Firstname" v-model="user.firstname"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation name="Lastname" rules="required">
              <RFInput label="Lastname" v-model="user.lastname"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation name="Birth Date" rules="required">
              <RFDatePicker label="Birth Date" v-model="user.birthDate"/>
            </RFValidation>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <RFValidation name="Email" rules="required|email">
              <RFInput label="Email" v-model="user.email"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation name="Nationality" rules="required">
              <RFInputAutocomplete label="Nationality" :value="user.nationality" :items="nationalities" v-model="user.nationality"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation name="Genre" rules="required">
              <RFSelect top-label="Genre" v-model="user.genre" :value="user.genre" @input="user.genre = $event" :list="genreList"/>
            </RFValidation>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <RFInput label="Status" v-model="user.status" readonly/>
          </b-col>
          <b-col>
            <RFValidation name="Country Code" rules="required">
              <RFInputAutocomplete label="Country Code" :value="user.countryCode" :items="countryCodes" v-model="user.countryCode"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation name="Whatsapp Contact" rules="required|phoneNumber">
              <RFInput label="Whatsapp Contact" is-numeric v-model="user.whatsappContact"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFInput label="Employment" v-model="user.employment"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <RFTextArea class="mt-3" label="Tell Us" v-model="user.tellUs"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <RFInput label="Document Number" v-model="user.docNumber"/>
          </b-col>
          <b-col>
            <RFInput label="Fiscal Code" v-model="user.fiscalCode"/>
          </b-col>
        </b-row>

        <h4 class="admin-subtitle mt-5">{{$t('Address')}}</h4>

        <b-row>
          <b-col>
            <RFInputAutocomplete label="Country" :value="user.addressCountry" :items="countries" v-model="user.addressCountry"/>
          </b-col>
          <b-col>
            <RFInput label="City" v-model="user.addressCity"/>
          </b-col>
          <b-col>
            <RFInput label="Zip Code" v-model="user.addressZipCode"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <RFInput label="Street" v-model="user.addressStreet"/>
          </b-col>
          <b-col>
            <RFInput label="Number" v-model="user.addressNumber"/>
          </b-col>
          <b-col>
            <RFInput label="Place of Birth" v-model="user.placeOfBirth"/>
          </b-col>
        </b-row>
        <b-row class="justify-content-end mt-5">
          <b-col cols="auto">
            <RFButton class="mt-3" label="Save" background="#5CB85C" @on-click="save" v-if="!loading"/>
            <RFLoader class="mt-3" color="#24425B" :size="30" v-else/>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>

    <div class="user-document" v-if="user.documents && user.documents.length">
      <h4 class="admin-subtitle mt-5">{{$t('Documents')}}</h4>

      <b-row class="mt-4">
        <b-col cols="3" v-if="isInDocuments(docTypes.PASSPORT)">
          <RFButton label="Show ID Document" full-width @on-click="showDocument(docTypes.PASSPORT)"/>
        </b-col>
        <b-col cols="3" v-if="isInDocuments(docTypes.ID_DOCUMENT,)">
          <RFButton label="Show ID Document" full-width @on-click="showDocument(docTypes.ID_DOCUMENT)"/>
        </b-col>
        <b-col cols="3" v-if="isInDocuments(docTypes.FISCALCODE)">
          <RFButton label="Show Fiscal Code" full-width @on-click="showDocument(docTypes.FISCALCODE)"/>
        </b-col>
        <b-col cols="3" v-if="isInDocuments(docTypes.UNIVERSITY)">
          <RFButton label="Show University Letter" full-width @on-click="showDocument(docTypes.UNIVERSITY)"/>
        </b-col>
      </b-row>
    </div>
    <DocumentModal :doc-type="selectedDocType"/>
  </div>
</template>

<script>
import RFValidation from '@/components/forms/RFValidation'
import RFInput from '@/components/forms/RFInput'
import RFDatePicker from '@/components/forms/RFDatePicker'
import RFTextArea from '@/components/forms/RFTextArea'
import RFInputAutocomplete from '@/components/forms/RFInputAutocomplete'
import RFButton from '@/components/forms/RFButton'
import RFLoader from '@/components/forms/RFLoader'
import RFSelect from '@/components/forms/RFSelect'

import DocumentModal from '@/components/admin/documents/DocumentModal'

import { docSides, docTypes, genres } from '@/enums'
import { mapState } from 'vuex'

import { userService } from '@/services/admin'

export default {
  name: 'AdminEditUser',
  components: {
    RFValidation,
    RFInput,
    RFDatePicker,
    RFInputAutocomplete,
    RFTextArea,
    RFButton,
    RFLoader,
    RFSelect,
    DocumentModal,
  },
  data() {
    return {
      docTypes,
      docSides,
      selectedDocType: null,
      user: null,
      loading: false,
      genreList: [],
    }
  },
  computed: {
    ...mapState({
      storedUser: state => state.adminUserStore.user,
      storedCountries: state => state.countryStore.countries,
    }),
    countries() {
      return this.storedCountries.map(country => country.name)
    },
    nationalities() {
      return this.storedCountries.map(country => country.nationality)
    },
    countryCodes() {
      // return this.storedCountries.map(country => country.name + ' - ' + country.dial_code)
      return this.storedCountries.map(country => country.name)
    },
  },
  beforeMount() {
    this.user = { ...this.storedUser }
    this.selectedDocType = this.user.documents[0].docType
    this.genreList = Object.keys(genres).map(genre => ({ label: genre, value: genre }))
  },
  methods: {
    showDocument(docType) {
      const document = this.extractDocument(docType)
      this.selectedDocType = docType
      this.$store.commit('adminUserStore/setDocument', document)
      this.$root.$emit('bv::show::modal', 'admin-document-modal')
    },
    extractDocument(docType) {
      let extractedDocument = {
        front: null,
        back: null,
        full: null,
      }

      this.user.documents.forEach(document => {
        if (document.docType === docType) {
          if (document.docSide === this.docSides.FRONT) {
            extractedDocument.front = document
          } else if (document.docSide === this.docSides.BACK){
            extractedDocument.back = document
          } else {
            extractedDocument.full = document
          }
        }
      })

      return extractedDocument
    },
    isInDocuments(docType) {
      return this.user.documents.find(document => document.docType === docType)
    },
    async save() {
      const isValid = await this.$refs.validator.validate()

      if (isValid) {
        this.loading = true

        const { data: updatedUser, status } = await userService.updateUser(this.user)

        if (status === 200) {
          this.user = { ...updatedUser }
          this.$toasted.success(this.$i18n.t(`User successfully edited!`))
        } else {
          this.$toasted.error(this.$i18n.t(`Error during edit user`))
        }

        this.loading = false
      }

    }
  }
}
</script>

<style style="scss" scoped>
</style>
